// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

// Images
import bgImage from "assets/images/examples/blog2.jpg";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const [form, setForm] = useState({ from_name: "", email: "", message: "" });
  const [isNameValid, setIsNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isMessageValid, setIsMessageValid] = useState(false);
  const [displayEmailFeedback, setDisplayEmailFeedback] = useState(false);

  useEffect(() => {
    if (form.from_name && form.from_name.trim() !== "") {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
    if (form.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
    if (form.message && form.message.trim() !== "") {
      setIsMessageValid(true);
    } else {
      setIsMessageValid(false);
    }
  }, [form]);

  useEffect(() => {
    setForm({ from_name: "", email: "", message: "" });
  }, [displayEmailFeedback]);

  const handleChange = (e, inputName) => {
    setForm({ ...form, [inputName]: e.target.value });
  };

  const sendEmail = () => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "contact_form",
        form,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          if (response.status === 200) {
            setDisplayEmailFeedback(true);
          }
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container item>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius="xl"
            shadow="xl"
            mb={6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                    palette: { gradients },
                    functions: { rgba, linearGradient },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                >
                  <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MKTypography variant="h3" color="white" mb={1}>
                      Contact
                    </MKTypography>
                    <MKBox display="flex" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-phone" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        (+36) 30 507 3857
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-envelope" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        hello@imagiklabs.com
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" color="white" p={1}>
                      <MKTypography variant="button" color="white">
                        <i className="fas fa-map-marker-alt" />
                      </MKTypography>
                      <MKTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Budapest, Hungary
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={3}>
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                        target="_blank"
                        href="https://www.facebook.com/outbreak.life.challenge"
                      >
                        <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                        target="_blank"
                        href="https://www.instagram.com/outbreak.life.challenge"
                      >
                        <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                      <MKButton
                        variant="text"
                        color="white"
                        size="large"
                        iconOnly
                        target="_blank"
                        href="https://www.linkedin.com/company/14844972/"
                      >
                        <i className="fab fa-linkedin" style={{ fontSize: "1.25rem" }} />
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} method="post">
                  <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Say Hi!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      We&apos;d love to hear from you.
                    </MKTypography>
                    {displayEmailFeedback && (
                      <MKAlert color="success" dismissible>
                        <MKBox>
                          <MKTypography variant="h4" color="white" mb={1}>
                            Thank you!
                          </MKTypography>
                          <MKTypography variant="body2" color="white">
                            We got your message and we&apos;ll soon get back to you.
                          </MKTypography>
                        </MKBox>
                      </MKAlert>
                    )}
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={form.from_name}
                          onChange={(e) => handleChange(e, "from_name")}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Email"
                          type="email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={form.email}
                          onChange={(e) => handleChange(e, "email")}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={6}>
                        <MKInput
                          variant="standard"
                          label="Your message"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          value={form.message}
                          onChange={(e) => handleChange(e, "message")}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MKButton
                        variant="gradient"
                        color="info"
                        onClick={sendEmail}
                        disabled={!isEmailValid || !isMessageValid || !isNameValid}
                      >
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contact;
