// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import one from "assets/images/bekind/imagiclabs/howitworks1.png";
import two from "assets/images/bekind/imagiclabs/howitworks2.png";
import three from "assets/images/bekind/imagiclabs/howitworks3.png";

function HowItWorks() {
  return (
    <MKBox component="section" py={12} id="howItWorks">
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={one}
              title="Create"
              description="Before an event, you create a story. This takes less than a minute."
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={two}
              title="Auto Upload"
              description="During the event, every picture all of you take will be automatically uploaded to the story"
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={three}
              title="Download"
              description="After the event everyone will see the pics automatically!"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default HowItWorks;
