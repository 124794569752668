/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";
import InfoBackgroundCard from "examples/Cards/BackgroundCards/InfoBackgroundCard";

function Features() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          mb={6}
        >
          <MKBox py={2} px={6} textAlign="center">
            <MKBox
              width="4rem"
              height="4rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              variant="gradient"
              bgColor="info"
              color="white"
              shadow="md"
              borderRadius="lg"
              mx="auto"
            >
              <Icon fontSize="medium">checklist</Icon>
            </MKBox>
            <MKTypography variant="h2" mt={2} mb={1}>
              Features
            </MKTypography>
            <MKTypography variant="body2" color="text">
              Imagic makes getting photos effortless. No more nagging everyone in the group chat.
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid container spacing={3} mb={6}>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              image="https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?q=80&w=2848&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              label="no fuss"
              title="Automatic"
              description="Every invited friend's pictures are uploaded automatically if they accept the invite."
            />
          </Grid>
          <Grid container spacing={3} item xs={12} md={6} lg={8}>
            <Grid item xs={12} md={6}>
              <InfoBackgroundCard
                image="https://images.unsplash.com/photo-1607682509155-dfb602079fc0?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                icon="flash_on"
                title="Instant"
                label="Pictures appear as they are being made"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBackgroundCard
                image="https://images.unsplash.com/photo-1578346693440-3dcedf4b7b81?q=80&w=2928&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                icon="phonelink_setup"
                title="Easy setup"
                label="Start capturing the event in less than a minute"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBackgroundCard
                image="https://images.unsplash.com/photo-1529156069898-49953e39b3ac?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                icon="rsvp"
                title="Invite your friends"
                label="No setup is needed on their side"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBackgroundCard
                image="https://images.unsplash.com/photo-1471623600634-4d04cfc56a27?q=80&w=2960&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                icon="watch_later"
                title="Download later"
                label="We save all your pictures for 4 days for free"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;
