/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import comingsoon from "assets/images/bekind/imagiclabs/comingsoon.png";
import bgImage4 from "assets/images/bekind/starterscreenshot.png";

function Projects() {
  return (
    <MKBox component="section" py={2} id="projects">
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Check out our latest projects!
          </MKTypography>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={bgImage4}
              title="outbreak.life"
              description="Learn about what truly makes people happy and what are the easy daily actions that lead them there"
              action={{
                type: "internal",
                route: "/outbreak",
                color: "info",
                label: "take a look",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={comingsoon}
              title="Imagic"
              description="To get everyone's pictures at an event, finally"
              action={{
                type: "internal",
                route: "/imagic",
                color: "info",
                label: "take a look",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects;
