// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/bekind/imagiclabs/party.jpg";
import post2 from "assets/images/bekind/imagiclabs/birthday.jpg";
import post3 from "assets/images/bekind/imagiclabs/wedding.jpg";
import post4 from "assets/images/bekind/imagiclabs/vacation.jpg";

function Events() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Amazing For
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Parties"
              description="A barbecue at home, a festival or just a Sunday hangout in the park."
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Birthdays"
              description="Family gatherings, kids parties and any kind of celebration together."
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Weddings"
              description="Instead of making everyone remember a wedding hashtag, get the pictures automatically."
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post4}
              title="Vacations"
              description="Get those holiday pictures during the holiday and right after without begging everyone."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Events;
