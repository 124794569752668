/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function HappinessIntro() {
  return (
    <MKBox component="section" py={6} mt={6} id="happinessIntro">
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
            The Great Happiness Puzzle
          </MKTypography>
          <MKTypography variant="h3" mb={3}>
            Redefining Happiness: Dive into the Four Key Elements
          </MKTypography>
          <MKTypography variant="body2">
            Imagine you&apos;re handed a jigsaw puzzle. It&apos;s called &quot;The Ultimate
            Happiness Puzzle.&quot; You&apos;re stoked. Who doesn&apos;t want to solve that? But as
            you dump the pieces out, you realize there&apos;s no picture on the box. No guide. Just
            a bunch of oddly shaped pieces.
            <br /> <br />
            Now, most people? They start jamming pieces together, hoping something fits. They might
            get a few pieces right, but the overall picture? It&apos;s a hot mess.
            <br /> <br />
            That&apos;s how most of us approach happiness. We&apos;ve got some of the pieces—maybe a
            Netflix subscription, a pet goldfish named Bob, and that yoga class we swear we&apos;ll
            start next week. But the full picture? It&apos;s elusive.
            <br /> <br />
            But what if I told you there&apos;s a clearer picture? Four big corner pieces that, once
            in place, make the rest of the puzzle a tad less puzzling. These aren&apos;t just any
            pieces; they&apos;re the pillars of genuine, long-lasting happiness:
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default HappinessIntro;
