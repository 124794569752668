// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Icon from "@mui/material/Icon";

function Benefits() {
  const bgImage =
    "https://images.unsplash.com/photo-1520880867055-1e30d1cb001c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  const data = [
    {
      icon: "lock",
      name: "Privacy - only people you invite will see the pics",
    },
    {
      icon: "filter_drama",
      name: "Ease of Use",
    },
    {
      icon: "add_a_photo",
      name: "Auto Upload",
    },
    {
      icon: "auto_delete",
      name: "Delete Later - remove any picture you don't like",
    },
    {
      icon: "auto_awesome",
      name: "No more asking for pics",
    },
    {
      icon: "cottage",
      name: "Download later - from the comfort of your home",
    },
  ];

  return (
    <>
      <MKBox component="section">
        <MKBox
          mx={-2}
          minHeight="18.75rem"
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
          }}
        />
        <Container>
          <Grid container item flexDirection="column" xs={12} lg={8} mt={12} mx="auto">
            <MKTypography
              component="h6"
              variant="button"
              textTransform="uppercase"
              fontWeight="bold"
              opacity={0.7}
              mb={1}
            >
              Benefits
            </MKTypography>
            <MKTypography variant="h3" mb={3}>
              Extra features for you
            </MKTypography>
            <MKTypography variant="body2">
              Get pictures securely, privately, instantly. Remove anything you don&apos;t like.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" py={8}>
        <Container>
          <Grid container item xs={12} md={9} mx="auto">
            {data.map(({ icon, name }) => (
              <Grid key={name} item xs={12} md={4} my={2}>
                <MKBox p={2} textAlign="center" borderRadius="lg">
                  <MKTypography variant="h3" color="info" mb={2} textGradient>
                    <Icon>{icon}</Icon>
                  </MKTypography>
                  <MKTypography variant="h6">{name}</MKTypography>
                </MKBox>
              </Grid>
            ))}
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Benefits;
