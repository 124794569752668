/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import ActionCard from "./ActionCard";
import { baseUrl } from "./urls";

const examples = [
  {
    id: 15,
    title: "Plant A Flower Bees Like",
    description:
      "The Little-Known Ways Your Garden Can Impact Global Food Chains (and Your Happiness)!",
    imageUrl:
      "https://images.unsplash.com/photo-1504392022767-a8fc0771f239?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1335&q=80",
    credit: "Unsplash - Aaron Burden",
    url: "plant-a-flower-bees-like",
  },
  {
    id: 7,
    title: "Make their favorite meal for a loved one",
    description: "Transform Your Day (and Theirs!) with the Power of a Homemade Dish!",
    imageUrl:
      "https://images.unsplash.com/photo-1616169776580-c86189ee67b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80",
    credit: "Unsplash - Micheile Henderson",
    url: "cook-a-meal",
  },
  {
    id: 8,
    title: "Say no to something you don't actually want to do",
    description: "Have a Life You Can Be Proud Of: Prioritize Genuine Commitments",
    imageUrl:
      "https://images.unsplash.com/photo-1518305846259-a810cb6afa1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    credit: "Unsplash - Jonathan Mast",
    url: "say-no",
  },
  {
    id: 18,
    title: "Write a good review for a small business",
    description:
      "The Lazy Shopper’s Way to Making A Difference: See How Easily You Can Support Local",
    imageUrl:
      "https://images.unsplash.com/photo-1573612664822-d7d347da7b80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    credit: "Unsplash - S O C I A L . C U T",
    url: "write-a-good-review-for-a-small-business",
  },
  {
    id: 33,
    title: "List Your Top 10 Values",
    description:
      "Because considering your values makes you authentic and improves your bonds with others.",
    imageUrl:
      "https://images.unsplash.com/photo-1606363968796-14be04915c81?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    credit: "Unsplash - S O C I A L . C U T",
    url: "list-your-top-10-values",
  },
  {
    id: 25,
    title: "Go By Bicycle Today",
    description: "Why You Should Totally Ditch Your Car and Hop on a Bicycle Today",
    imageUrl:
      "https://images.unsplash.com/photo-1501147830916-ce44a6359892?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    credit: "Unsplash - S O C I A L . C U T",
    url: "go-by-bicycle-today",
  },
];

function ExploreActions() {
  function actionProps(type) {
    return {
      type: "external",
      route: `${baseUrl}/${type.id}/${type.url}`,
      color: "dark",
      label: "check it out",
    };
  }

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Small acts of happiness"
            container
            circular
            sx={{ mb: 1 }}
          />
          <MKTypography variant="h2" mb={1}>
            Explore actions{" "}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            What are the small things you can do to make yourself and others happy? See some
            examples!
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {examples.map((example, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <MKBox mt={3}>
                <ActionCard
                  image={example.imageUrl}
                  title={example.title}
                  description={example.description}
                  action={actionProps(example)}
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ExploreActions;
