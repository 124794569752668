/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

function Values() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          textAlign="center"
          mx="auto"
          mb={3}
        >
          <MKTypography
            variant="body2"
            color="info"
            fontWeight="bold"
            textTransform="uppercase"
            textGradient
          >
            Values
          </MKTypography>
          <MKTypography variant="h3">We believe in</MKTypography>
        </Grid>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="dark"
              image="https://images.unsplash.com/photo-1615801627253-eae9c5be334e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              label="Resilience"
              title="Let's learn!"
              description="We test, we measure, we improve. We are not afraid to fail."
              action={{
                type: "external",
                route: "https://www.ted.com/talks/richard_st_john_8_secrets_of_success?language=en",
                label: "A video we love about success",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="dark"
              image="https://images.unsplash.com/photo-1534180079718-c54f5e889c4f?q=80&w=3088&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              label="Growth"
              title="Let's get feedback!"
              description="We get feedback and we improve. There is always a better way to do things so why not find out?"
              action={{
                type: "external",
                route:
                  "https://www.ted.com/talks/benjamin_zander_the_transformative_power_of_classical_music?language=en",
                label: "One more video about our values",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="dark"
              image="https://images.unsplash.com/photo-1437482078695-73f5ca6c96e2?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              label="Fun"
              title="We are driven by our passion"
              description="We love coding, thats why we are doing it. We love building and creating."
              action={{
                type: "external",
                route:
                  "https://www.ted.com/talks/benjamin_zander_the_transformative_power_of_classical_music?language=en",
                label: "And one more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Values;
