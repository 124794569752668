/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function WeReallyWanted() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto">
          <MKTypography
            component="h6"
            variant="button"
            opacity={0.7}
            textTransform="uppercase"
            fontWeight="bold"
          >
            Meet Outbreak
          </MKTypography>
          <MKTypography variant="h3" mb={3}>
            We wanted to create an app that makes the world a better place.
          </MKTypography>
          <MKTypography variant="body2">
            Thats why we created Outbreak: to help people be happier, more fulfilled by training
            their minds through small every day actions. We read a bunch of books and studies on
            life, effectiveness and what makes people more fulfilled. We thought long and hard about
            what the best actions are: the ones which are small enough to fit into your busy day but
            the ones that also make a difference for you.
            <br /> <br />
            Lets see a bit more about the science behind it!
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WeReallyWanted;
