/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBackgroundCard from "examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "pages/Support/HelpCenter/components/ListItem";

// Images
import bgImage1 from "assets/images/bekind/selfcare.jpg";
import bgImage2 from "assets/images/bekind/community.jpg";
import bgImage3 from "assets/images/bekind/purpose.jpg";
import bgImage4 from "assets/images/bekind/friends1.jpg";
import { baseUrl } from "./urls";

function FourIngredients() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          lg={5}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKBox
            width="3rem"
            height="3rem"
            borderRadius="lg"
            shadow="md"
            variant="gradient"
            bgColor="info"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon fontSize="small" sx={{ opacity: 0.8 }}>
              favorite
            </Icon>
          </MKBox>
          <MKTypography variant="h3" mt={3}>
            The Science Behind What Brings Us Longlasting Satisfaction
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Why did we pick certain activities into Outbreak?
          </MKTypography>
        </Grid>
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <DefaultBackgroundCard
              image={bgImage1}
              label="doing self-care well"
              title="Treat yourself with things that matter"
              description="When we think of things that make us happy, we often picture indulgent treats or binge-worthy shows. But true happiness? It's a bit more nuanced than that. We've delved deep and unearthed activities that not only give you an instant mood lift but also have long-term positive effects on your well-being."
              action={{
                type: "external",
                route: `${baseUrl}?category=You`,
                label: "explore all self-care ideas",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <ListItem title="For example, exercise">
              Remember those days when you felt invincible after a good workout? It&apos;s not just
              in your head.{" "}
              <MKTypography
                component="a"
                href="https://www.sciencedirect.com/science/article/abs/pii/S0378512217308563"
                target="_blank"
                rel="noreferrer"
                fontWeight="regular"
                variant="body2"
              >
                Research
              </MKTypography>{" "}
              has shown that exercise improves anxiety, stress, and depression. It&apos;s not just
              about the endorphins; exercise has a profound effect on our mental health.
            </ListItem>
            <ListItem title="Or Nature time">
              Ever felt an inexplicable calm while walking in a forest or sitting by a lake?
              There&apos;s science behind that serenity. Spending time in nature enhances cognitive
              function and mental health, as highlighted in this{" "}
              <MKTypography
                component="a"
                href="http://texanbynature.org/wp-content/uploads/2016/10/Bratman-et-al-2012-Nature-Experience-Cognitive-Function-and-Mental-Health-NY-ACAD-SCI.pdf"
                target="_blank"
                rel="noreferrer"
                fontWeight="regular"
                variant="body2"
              >
                study
              </MKTypography>
              .
            </ListItem>
            <ListItem title="Gratitude">
              Practicing daily gratitude can significantly boost your mood. But this is just the tip
              of the iceberg. Dive deeper, and you&apos;ll find a treasure trove of self-care habits
              that can transform your life.
            </ListItem>
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <ListItem title="Being part of a community">
              Humans are social creatures. We thrive on connections. But did you know that low
              social interaction can be as harmful as smoking 15 cigarettes a day? Yikes! As
              highlighted by the{" "}
              <MKTypography
                component="a"
                href="https://www.denverhealthmedicalplan.org/blog/science-says-these-five-things-will-make-you-happier"
                target="_blank"
                rel="noreferrer"
                fontWeight="regular"
                variant="body2"
              >
                same source
              </MKTypography>{" "}
              , surrounding yourself with positive people can uplift your spirits. Happiness is,
              quite literally, contagious. So, make that call to a friend, join that community
              group, or simply share a smile with a stranger. Your brain will thank you.
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <DefaultBackgroundCard
              image={bgImage2}
              label="social activities"
              title="Connection is easier than you think "
              description="Find small ways in your neighborhood, workplace or even during traffic you can feel more connected to others!"
              action={{
                type: "external",
                route: `${baseUrl}?category=Social`,
                label: "explore all social ideas",
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <DefaultBackgroundCard
              image={bgImage3}
              label="finding meaning"
              title="Find your purpose"
              description="We are all part of a bigger thing and we get happier when we embrace that."
              action={{
                type: "external",
                route: `${baseUrl}?category=Environment`,
                label: "explore all ideas for your purpose",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <ListItem title="You can find the direction in your life that keeps you going">
              Purpose is different for all of us. And it is undeniably hard to find. But there are a
              couple directions which might help.
            </ListItem>
            <ListItem title="Helping nature, raising children and meaningful work">
              Helping our planet become more healthier. Or finding the work that is truly meaningful
              for you. Trying to be a great parent. These are all things that matter so much. We
              tried to find activites that can help you find your calling, whatever your path is.
            </ListItem>
          </Grid>
        </Grid>
        <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <ListItem title="Prioritizing loved ones">
              Remember those old sitcoms where everyone knows everyone? There&apos;s a reason
              they&apos;re so popular. Close relationships, be it with family, friends, or even
              pets, play a pivotal role in our happiness. The{" "}
              <MKTypography
                component="a"
                href="https://news.harvard.edu/gazette/story/2017/04/over-nearly-80-years-harvard-study-has-been-showing-how-to-live-a-healthy-and-happy-life/"
                target="_blank"
                rel="noreferrer"
                fontWeight="regular"
                variant="body2"
              >
                Harvard Study
              </MKTypography>{" "}
              has been tracking the health of 268 sophomores for nearly 80 years, and guess what?
              The happiest and healthiest participants were those who maintained close
              relationships. So, cherish those late-night talks, family dinners, and spontaneous
              road trips with friends. They&apos;re the stuff happiness is made of.
            </ListItem>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}>
            <DefaultBackgroundCard
              image={bgImage4}
              label="family, friends & your partner"
              title="Close relationships"
              description="Close relationships are the thing that determines our happiness the most."
              action={{
                type: "external",
                route: `${baseUrl}?category=Relationships`,
                label: "explore all close relationship ideas",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FourIngredients;
