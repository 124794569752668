/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { baseUrl } from "./urls";

function WhyYoullLove() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h2" my={1}>
            Why You&apos;ll Love Outbreak
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Shaping your mindset into a more fulfilled version just 10 minutes a day.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="tips_and_updates"
              title="The Ideas"
              description="We carefully collected fun and easy ideas, most of them you can do in under 10 minutes"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="done"
              title="Simple"
              description="Just pick an idea and do it today!"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <DefaultInfoCard
              icon="all_inclusive"
              title="The Feeling"
              description="Our App Motivates You - But Your Own Feelings Will The Best Reward."
              direction="center"
            />
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            lg={6}
            sx={{ mx: "auto", pb: 3, textAlign: "center" }}
          >
            <MKButton variant="gradient" color="info" href={baseUrl} target="_blank">
              check out Outbreak
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhyYoullLove;
