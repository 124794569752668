// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

import emailjs from "@emailjs/browser";

// Images
import image from "assets/images/bekind/imagiclabs/subscribe.jpg";
import { useState, useEffect } from "react";

function Subscribe() {
  const [form, setForm] = useState({ email: "" });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [displayEmailFeedback, setDisplayEmailFeedback] = useState(false);

  useEffect(() => {
    if (form.email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }, [form]);

  useEffect(() => {
    setForm({ email: "" });
  }, [displayEmailFeedback]);

  const handleChange = (e) => {
    setForm({ email: e.target.value });
  };

  const sendEmail = () => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "imagic_subscription",
        form,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          if (response.status === 200) {
            setDisplayEmailFeedback(true);
          }
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <MKBox component="section" py={12} id="subscribe">
      <MKBox bgColor="grey-100" py={12} px={{ xs: 3, lg: 0 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6} ml="auto">
            <MKTypography variant="h4" mb={1}>
              We Launch In March 2024
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Let us know if you would like to get notified first.
            </MKTypography>
            <Grid container spacing={2}>
              {displayEmailFeedback && (
                <MKAlert color="success" dismissible>
                  <MKBox>
                    <MKTypography variant="h4" color="white" mb={1}>
                      Thank you for your interest!
                    </MKTypography>
                    <MKTypography variant="body2" color="white">
                      You will be the first we notify when we launch!
                    </MKTypography>
                  </MKBox>
                </MKAlert>
              )}
              <Grid item xs={12} sm={5}>
                <MKInput
                  variant="standard"
                  label="Email"
                  type="email"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={form.email}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MKButton
                  variant="gradient"
                  color="warning"
                  sx={{ height: "100%" }}
                  onClick={sendEmail}
                  disabled={!isEmailValid}
                >
                  Subscribe
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4} position="relative">
            <MKBox
              component="img"
              src={image}
              alt="image"
              maxWidth="18.75rem"
              width="100%"
              borderRadius="lg"
              shadow="xl"
              mt={-24}
              display={{ xs: "none", lg: "block" }}
            />
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default Subscribe;
