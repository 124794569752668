// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";

// Images
import post1 from "assets/images/bekind/imagiclabs/betterplace.jpg";
import post2 from "assets/images/bekind/imagiclabs/tech.jpg";
import post3 from "assets/images/bekind/imagiclabs/quick2.jpg";

function WhatWeDo() {
  return (
    <MKBox component="section" py={7}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={9} textAlign="center" mx="auto">
            <MKTypography variant="h3" mb={0.5}>
              What We Do
            </MKTypography>
            <MKTypography variant="body2" color="text" px={{ xs: 0, md: 6 }} mb={4}>
              We build PWA apps / websites.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post1}
              category={{ color: "primary", label: "we want to do good" }}
              title="Apps that make the world a better place"
              description="So that society improves and people’s lives get better. For instance, we just created an app that challenges users to do daily actions that are proven to increase wellbeing."
              action={{ type: "internal", route: "/pages/blogs/single-article" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post2}
              category={{ color: "info", label: "we want to be great" }}
              title="State-of-the art technologies on frontend, backend and devops"
              description="So our sites are always fast, secure and easy-to-use. For example, we just switched to the latest frontend technology, NextJs."
              action={{ type: "internal", route: "/pages/blogs/single-article" }}
            />
          </Grid>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <DefaultBlogCard
              image={post3}
              category={{ color: "warning", label: "we want to act quick" }}
              title="Shipping a new app every 3-4 months"
              description="So we can measure, test and grow quickly. We are probably the only team who started a brand new project on 3 December and had progress by the 16th."
              action={{ type: "internal", route: "/pages/blogs/single-article" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default WhatWeDo;
